<template>
  <div class="caseMain-wrap70">
    <div class="head">
      <header2></header2>
    </div>
    <div class="caseMain-wrap70content">
      <div class="main-wrap">
        <div class="content">
          <div class="regHd">
            <div class="back"><el-button class="back-btn" @click="back()" icon="el-icon-back" type="primary" size="mini" plain round>返回</el-button></div>
            家长个人信息
          </div>
          <div class="tabs">
            <div class="li-tab" @click="cur=0" :class="{active:cur==0}">
              <img :src="picImgSrc.url" alt="">
            </div>
          </div>
          <div class="Tab" v-show="cur==1">
            <el-form
                    ref="ruleForm"
                    :model="ruleForm"
                    status-icon
                    :rules="rules"
                    label-width="100px"
            >
              <div class="reg-warp">
                <div class="personalB">
                  <div class="p-rg">
                    <el-form-item label="姓名" prop="familyName">
                      <el-input v-model="ruleForm.familyName" style="width: 450px"></el-input>
                      <span class="test">姓名作为实名制信息，不能修改，请认真输入</span>
                    </el-form-item>
                    <el-form-item label="头像" prop="">
                      <div class="portraitList">
                        <img v-for="(item,index) in portraitList" :key="index"
                             :class="{'onClickStyle':clickFlag == index}" @click="onClickItem(item,index)"
                             :src="item.url" alt=""/>
                      </div>
                    </el-form-item>
                    <el-form-item label="性别" prop="">
                      <el-select v-model="ruleForm.gender" @change="xbChange" placeholder="请选择" style="width: 450px">
                        <el-option
                                v-for="item in stateList"
                                :key="item.id"
                                :label="item.name"
                                :value="item.id">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </div>
                </div>
              </div>
              <div class="btn-warp">
                <el-button type="primary" @click="regForm('ruleForm')">提 交</el-button>
              </div>
            </el-form>

          </div>
        </div>
      </div>
    </div>

  </div>


</template>


<script>
  import { mapState } from 'vuex'
  import header2 from '@/components/layout/header2.vue'
  import {getParFamilyInfoObj,getParChildrenObj} from '@/api/parent'
  import {updateFamilyObj} from '@/api/login'

  export default {
    name: '',
    inject: ['reload'],
    data() {

      return {
        clickFlag:null,
        ParFamilyInfoData:{},
        findScreenGradeData:[],

        successDialog: false,
        AuthorstuName: '',

        cur: 1,//默认选中第一个tab
        stateList: [
          {
            name: '男',
            id: 1
          },
          {
            name: '女',
            id: 2
          }
        ],
        portraitList: [
          {id: 1, url: require('../../assets/images/m1.png')},
          {id: 2, url: require('../../assets/images/m2.png')},
          {id: 3, url: require('../../assets/images/m3.png')},
          {id: 4, url: require('../../assets/images/m4.png')},
          {id: 5, url: require('../../assets/images/m5.png')},
        ],
        ruleForm: {
          familyName: "",//家长姓名
          profilePhotoPath: "",//头像存储路径
          gender: "",//性别
        },
        rules: {
          familyName: [
            {
              required: true,
              message: '请输入家长姓名',
              trigger: 'blur',
            },
            {
              min: 1,
              max: 30,
              message: '家长姓名长度必须在 1 到 30个字符',
              trigger: 'blur',
            },
          ],

          stuName: [
            {
              required: true,
              message: '请输入用户名',
              trigger: 'blur',
            },
            {
              min: 1,
              max: 30,
              message: '用户名长度必须在 1 到 30个字符',
              trigger: 'blur',
            },
          ],
          nickName: [
            {
              required: true,
              message: '请输入昵称',
              trigger: 'blur',
            },
          ],
          profilePhotoPath: [
            {
              required: true,
              message: '请选择用户头像',
              trigger: 'blur',
            },
          ],





        },

      }
    },
    created() {
      this.paruserInfo = JSON.parse(localStorage.getItem("paruserInfo"));
      this.clickFlag= Number(this.ParFamilyInfo.profilePhotoPath)-1
      this.portraitList.forEach((ele) => {
        this.profileId= ele.id
        this.ruleForm.profilePhotoPath=this.ParFamilyInfo.profilePhotoPath
        if (this.ruleForm.profilePhotoPath == ele.id){
          this.picImgSrc = ele
          console.log(this.picImgSrc,'990000------')
        }
      })
      this.getParFamilyInfo()

    },
    mounted() {

    },
    methods: {
      //返回
      back() {
        this.$router.go(-1);
      },
      // 查询家长信息  paruserInfo
      getParFamilyInfo() {
        let obj = {
          userName:this.paruserInfo.userName
        }
        getParFamilyInfoObj(obj).then((res) => { // eslint-disable-line no-unused-vars
          if (res.code == 200) {
            this.ruleForm = res.data
            this.$store.commit('saveParFamilyInfo', res.data)
          }
        })
      },
      // 查询家长信息  paruserInfo
      getParChildren() {
        let obj = {
          studentMobileNumber:this.paruserInfo.studentMobileNumber
        }
        getParChildrenObj(obj).then((res) => { // eslint-disable-line no-unused-vars
          if (res.code == 200) {
            //this.StuByUser = res.data
            this.$store.commit('saveParChildren', res.data)
          }
        })
      },
      xbChange($event) {
        this.ruleForm.gender = $event
      },


      //点击改变文字颜色
      onClickItem(item, index) {
        this.clickFlag = index;
        this.ruleForm.profilePhotoPath = item.id;
        this.portraitList.forEach((ele) => {
          this.profileId= ele.id
          this.ruleForm.profilePhotoPath=this.ParFamilyInfo.profilePhotoPath
          if (this.ruleForm.profilePhotoPath == ele.id){
            this.picImgSrc = ele
          }
        })
      },
      GradeChang($event){
        this.ruleForm.gradeId = $event;
      },



      regForm(formName) {

        this.$refs[formName].validate(valid => {
          if (valid) {
            let params = {
              familyName: this.ruleForm.familyName,
              profilePhotoPath: this.ruleForm.profilePhotoPath,
              gender: this.ruleForm.gender,
              id:this.ParChildren.id
            }
            updateFamilyObj(params).then((res) => { // eslint-disable-line no-unused-vars
              if (res.code == 200) {
                this.getParFamilyInfo()
                this.reload()
                this.$message({
                  message: res.message,
                  type: 'success'
                });

                console.log(res)
              } else{
                this.message = res.message
                this.$message.warning(this.message);
              }
            })
          }
        })

      },
      regObj() {
        localStorage.removeItem("AuthorstuName");
        localStorage.removeItem("userInfo");
        localStorage.removeItem("status");
        localStorage.removeItem("Authorization");
        this.successDialog = false
        this.$router.replace({
          path: "/",
          meta: {allowBack: false}
        });
      },




    },
    computed:{
      ...mapState([
        'ParFamilyInfo',
        'ParChildren'
      ]),
    },
    components: {
      // 图标
      header2,

      //注册图标组件名称
    }
  }
</script>
<style lang="scss" scoped>
  .back-btn {
    border: 1px solid #c4e7ff;padding: 0px 10px; height:32px;line-height:32px; background-color: #37AEFF; color:#c4e7ff
  }
  .caseMain-wrap70 {
    width: 100%;
    display: flex;
    background-size: 100% 100%;
    flex-direction: column;
    position: relative;
    box-sizing: border-box;
    flex-direction: column;
    overflow-y: hidden;

  .head {
    width: 100%;
    height: 70px;
    position: fixed;
    z-index: 0;
  }

  .caseMain-wrap70content {
    width: 100%;
    position: fixed;
    /*固定定位*/
    top: 70px;
    bottom: 0;
    .main-wrap {
       height: 100%;
      .content {
        width: 98%;
        height: 100%;
        box-sizing: border-box;
        .regHd {
          width: 100%;
          height: 50px;
          line-height: 50px;
          font-size: 15px;
          font-weight: bold;
          text-align: center;
          border-bottom: 1px solid #238bff;
          position: relative;
          .back {
            position: absolute;
            justify-content: flex-start;
            left: 0px;
          }
        }
      }

    }


  }

  }



  .tabs {
    width: 100%;
    height: 90px;
    font-size: 15px;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;

  .active {
    border-radius: 50px;
    border: 1px solid #F77134;
    background: #FFFCB2;
    color: #ff6820;
  }

  .li-tab {
    width: 60px;
    height: 60px;
    border-radius: 50px;
    border: 1px solid #bbb;
    margin: 0px 25px 0 25px;
    display: inline-block;
    text-align: center;
    font-size: 14px;
    cursor: pointer;

  img {
    width: 60px;
    height: 60px;
    border-radius: 50px;
  }

  .t1 {
    text-align: center;
  }
  }


  }
  .btn-reg {
    height: 40px;
    color: #FFF;
  }
  .Tab {
    display: block;
    width: 100%;
    height: 670px;
    margin-top: 10px;

  .reg-warp {
    width: 90%;
    margin: auto;
    padding: 0 50px;
    box-sizing: border-box;
    overflow-y: auto;

  .test {
    margin-left: 30px;
    color: #b1bcd6;
  }

  .personal {
    width: 100%;
    display: flex;
    margin-top: 20px;

  .p-lf {
    height: 500px;
    display: flex;
    padding-right: 30px;
    align-items: center;
    text-align: center;
    border-right: 1px dashed #d5dee9;

    width: 20px;
    line-height: 24px;
    font-size: 16px;
    font-weight: bold;
  }

  .p-rg {
    width: 1200px;
    height: 800px;

  .portraitList {
    width: 100%;

  img {
    width: 50px;
    height: 50px;
    margin-right: 20px;
    float: left;

  }
  }

  .onClickStyle {
    width: 50px;
    height: 50px;
    background-color: #238bff;
    border: 2px solid #238bff;
    border-radius: 50px;

  img {

  }
  }
  .onClickStyleB {
    width: 50px;
    height: 50px;
    background-color: #238bff;
    border: 2px solid #238bff;
    border-radius: 50px;

  img {

  }
  }

  //background-color: #238bff;
  }
  }
  .personalB {
    width: 100%;
    display: flex;
    margin-top: 20px;

  .p-lf {
    height: 200px;
    display: flex;
    padding-right: 30px;
    align-items: center;
    text-align: center;
    border-right: 1px dashed #d5dee9;

    width: 20px;
    line-height: 24px;
    font-size: 16px;
    font-weight: bold;
  }

  .p-rg {
    width: 1200px;
    height: 300px;

  .portraitList {
    width: 100%;

  img {
    width: 50px;
    height: 50px;
    margin-right: 20px;
    float: left;

  }
  }

  .onClickStyle {
    width: 50px;
    height: 50px;
    background-color: #238bff;
    border: 2px solid #238bff;
    border-radius: 50px;

  img {

  }
  }
  .onClickStyleB {
    width: 50px;
    height: 50px;
    background-color: #238bff;
    border: 2px solid #238bff;
    border-radius: 50px;

  img {

  }
  }

  //background-color: #238bff;
  }
  }

  .account {
    width: 100%;
    display: flex;
    margin-top: 40px;

  .p-lf {
    height: 320px;
    display: flex;
    padding-right: 30px;
    align-items: center;
    text-align: center;
    border-right: 1px dashed #d5dee9;

    width: 20px;
    line-height: 24px;
    font-size: 16px;
    font-weight: bold;
  }

  .p-rg {
    width: 1200px;
    height: 320px;

  .portraitList {
    width: 100%;

  img {
    width: 50px;
    height: 50px;
    margin-right: 20px;
    float: left;
  }
  }

  //background-color: #238bff;
  }
  }

  .parentpersonal {
    width: 100%;
    display: flex;
    margin-top: 20px;

  .p-lf {
    height: 190px;
    display: flex;
    padding-right: 30px;
    align-items: center;
    text-align: center;
    border-right: 1px dashed #d5dee9;

    width: 20px;
    line-height: 24px;
    font-size: 16px;
    font-weight: bold;
  }

  .p-rg {
    width: 1200px;
    height: 190px;

  .portraitList {
    width: 100%;

  img {
    width: 50px;
    height: 50px;
    margin-right: 20px;
    float: left;
  }
  }

  //background-color: #238bff;
  }
  }

  .parentaccount {
    width: 100%;
    display: flex;
    margin-top: 40px;

  .p-lf {
    height: 230px;
    display: flex;
    padding-right: 30px;
    align-items: center;
    text-align: center;
    border-right: 1px dashed #d5dee9;

    width: 20px;
    line-height: 24px;
    font-size: 16px;
    font-weight: bold;
  }

  .p-rg {
    width: 1200px;
    height: 230px;

  .tip {
    width: 96%;
    height: 40px;
    line-height: 40px;
    padding: 0 10px;
    margin: auto auto 10px auto;
    background: rgba(255, 251, 229, 0.8);
    border: 1px solid #FF9B36;
    opacity: 1;
    color: #D50007;
  }

  .portraitList {
    width: 100%;

  img {
    width: 50px;
    height: 50px;
    margin-right: 20px;
    float: left;
  }
  }

  //background-color: #238bff;
  }
  }
  }

  .btn-warp {
    width: 100%;
    height: 40px;
    margin-top: 20px;
    padding-bottom: 20px;
    text-align: center;
  }

  ::v-deep.dialogHd {
    text-align: center;
  }
  }
</style>
